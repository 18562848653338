import {hydrate} from 'react-dom';
import {IntlProvider} from 'react-intl';
import {QueryClient, QueryClientProvider} from 'react-query';
import {RemixBrowser} from 'remix';

import {LocaleContext} from '~/helpers/language-context';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});
hydrate(
    <LocaleContext.Provider value={window.CB.locale}>
        <QueryClientProvider client={queryClient}>
            <IntlProvider
                messages={window.CB.language}
                locale={window.CB.locale.language}
            >
                <RemixBrowser />
            </IntlProvider>
        </QueryClientProvider>
    </LocaleContext.Provider>,
    document,
);
